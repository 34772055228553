import SpinnerStyle from './styles';

export const Spinner = ({ className }: { className?: string }) => {
  return (
    <SpinnerStyle
      className={className || ''}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Spinner">
        <rect
          id="325"
          opacity="0.87"
          x="8.87891"
          y="8.1377"
          width="2.11111"
          height="5.27778"
          rx="1.05556"
          transform="rotate(135 8.87891 8.1377)"
          fill="currentColor"
        />
        <rect
          id="270"
          opacity="0.75"
          x="7.35938"
          y="9.69458"
          width="2.11111"
          height="5.27778"
          rx="1.05556"
          transform="rotate(90 7.35938 9.69458)"
          fill="currentColor"
        />
        <rect
          id="225"
          opacity="0.63"
          x="7.38672"
          y="11.8696"
          width="2.11111"
          height="5.27778"
          rx="1.05556"
          transform="rotate(45 7.38672 11.8696)"
          fill="currentColor"
        />
        <rect
          id="180"
          opacity="0.51"
          x="8.94531"
          y="13.3889"
          width="2.11111"
          height="5.27778"
          rx="1.05556"
          fill="currentColor"
        />
        <rect
          id="135"
          opacity="0.39"
          x="16.3477"
          y="15.6016"
          width="2.11111"
          height="5.27778"
          rx="1.05556"
          transform="rotate(135 16.3477 15.6016)"
          fill="currentColor"
        />
        <rect
          id="90"
          opacity="0.27"
          x="17.918"
          y="9.69458"
          width="2.11111"
          height="5.27778"
          rx="1.05556"
          transform="rotate(90 17.918 9.69458)"
          fill="currentColor"
        />
        <rect
          id="45"
          opacity="0.15"
          x="14.8516"
          y="4.40576"
          width="2.11111"
          height="5.27778"
          rx="1.05556"
          transform="rotate(45 14.8516 4.40576)"
          fill="currentColor"
        />
        <rect
          id="0"
          x="8.94531"
          y="2.83325"
          width="2.11111"
          height="5.27778"
          rx="1.05556"
          fill="currentColor"
        />
      </g>
    </SpinnerStyle>
  );
};
