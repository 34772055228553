import styled from "styled-components";

const SpinnerStyle = styled.svg`
  rect,
  rect:after {
    box-sizing: border-box;
  }
  color: var(--sky200);
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  rect {
    animation: opacity 1.2s linear infinite;
  }

  rect:nth-child(8) {
    animation-delay: -1.05s;
  }
  rect:nth-child(7) {
    animation-delay: -0.9s;
  }
  rect:nth-child(6) {
    animation-delay: -0.75s;
  }
  rect:nth-child(5) {
    animation-delay: -0.6s;
  }
  rect:nth-child(4) {
    animation-delay: -0.45s;
  }
  rect:nth-child(3) {
    animation-delay: -0.3s;
  }
  rect:nth-child(2) {
    animation-delay: -0.15s;
  }
  rect:nth-child(1) {
    animation-delay: 0s;
  }

  @keyframes opacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default SpinnerStyle;