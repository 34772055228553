import { Fragment, useEffect, useRef, useState } from 'react';
import { withLanguageContext, withUIContext } from '@/context';
import { encodeTitle } from '@/helpers/_functions';

import Text from '../Text';
import Image from '../Image';
import Icon from '../Icon';
import CustomLink from '../CustomLink';
import PopupShare from '../PopupShare';
import PostStyle, { ListWrapper } from './style';
import { pushDataLayer } from '@/helpers';

const Post = ({
  id,
  title,
  text,
  username = 'Yan from Owner.One',
  userpic,
  openPopup,
  translate,
  isInFavourite,
  addToFavourite,
  showNotification,
  popupIsOpen,
  isActive,
  setIsActive,
  hasList = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [canExpand, setCanExpand] = useState(true);
  const [isSharePopup, setIsSharePopup] = useState(false);
  const ref = useRef();
  const wrapper = useRef();
  const content = useRef();

  const expand = (e) => {
    pushDataLayer({
      event: isOpen ? 'yansnotes_post_collapse' : 'yansnotes_post_expand',
      target: e.target,
      element: e.currentTarget,
    });
    if (isOpen) {
      setIsActive(false);
    }
    setIsOpen((prev) => {
      if (!prev && wrapper.current) {
        setTimeout(() => {
          if (!wrapper.current) return;
          const elRect = wrapper.current.getBoundingClientRect();
          const parentRect =
            wrapper.current.parentElement.getBoundingClientRect();
          const count =
            elRect.top - parentRect.top + elRect.height - parentRect.height;
          if (count > 0) {
            wrapper.current?.parentElement.scrollBy({
              top: count,
              left: 0,
              behavior: 'smooth',
            });
          }
        }, 250);
      }
      return !isOpen;
    });
  };

  const copyText = (e) => {
    const html = `<b>${title}</b><br><p>${text}</p>`;
    const plain = `${title}\n${text}`;
    const clipboardItem = new ClipboardItem({
      'text/html': new Blob([html], { type: 'text/html' }),
      'text/plain': new Blob([plain], { type: 'text/plain' }),
    });
    navigator.clipboard.write([clipboardItem]);
    pushDataLayer({
      event: 'yansnotes_post_copy_text',
      target: e.target,
      element: e.currentTarget,
    });
    showNotification({
      type: 'success',
      title: translate('textCopied'),
    });
    setIsActive(false);
  };

  const share = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    pushDataLayer({
      event: 'yansnotes_share_click',
      target: e.target,
      element: e.currentTarget,
    });
    openPopup(
      <PopupShare
        id={id}
        title={title}
        text={text}
        url={`${location.origin}/yansnotes/${encodeTitle(title)}`}
      />,
      {
        paStyle: true,
        hideBackdrop: true,
        position: [rect.x + rect.width / 2, rect.y],
        title: translate('shareTo'),
      }
    );
    setIsSharePopup(true);
    setIsActive(false);
  };

  const addToFavouriteHandler = (e) => {
    addToFavourite();
    pushDataLayer({
      event: 'yansnotes_add_to_favourite',
      target: e.target,
      element: e.currentTarget,
    });
    setIsActive(false);
  };

  const getList = (matchFromRegex) => {
    if (!Array.isArray(matchFromRegex)) return;

    return matchFromRegex.flatMap((listArray) => {
      if (!Array.isArray(listArray)) return [];

      return listArray.map((list) => {
        const tag = list.slice(1, 3);
        const items = list
          .replace(/<\/?[ou]l>/g, '')
          .replace(/<li>/g, '')
          .split('</li>')
          .filter((item) => item.trim() !== '');
        const string = list;

        return { tag, items, string };
      });
    });
  };

  useEffect(() => {
    if (ref.current) {
      setCanExpand(ref.current.offsetHeight < ref.current.scrollHeight);
    }
  }, [ref]);

  useEffect(() => {
    if (!popupIsOpen) {
      setIsSharePopup(false);
    }
  }, [popupIsOpen]);

  useEffect(() => {
    if (content.current) {
      content.current.style.setProperty(
        '--max-height',
        `${ref.current.scrollHeight + 700}px`
      );
    }
  }, [content, ref]);

  const MainContent = (() => {
    let textRem = text;
    let content = [];
    let caret = 0;

    const pushElement = (item, element, nameOfAnElement) => {
      const idx = item.index;
      const len = item[0].length;

      switch (nameOfAnElement) {
        case 'list':
          const formattedText = textRem
            .slice()
            .replace(/<[ou]l>(.*)<\/[ou]l>/, '');
          content.push(formattedText);
          content.push(element);
          textRem = '';
          break;
        default:
          content.push(text.slice(caret, idx));
          content.push(element);
          textRem = text.slice((caret = idx + len));
          break;
      }
    };

    const links = Array.from(text.matchAll(/<a.*href="(.*)">(.*)<\/a>/gm));

    links.forEach((item) => {
      const el = (
        <CustomLink target="_blank" className={`link`} url={item[1]}>
          {item[2]}
        </CustomLink>
      );
      pushElement(item, el, 'link');
    });

    const arrayOfLists = Array.from(text.matchAll(/<[ou]l>.*<\/[ou]l>/gm));
    // console.log(arrayOfLists)
    getList(arrayOfLists).forEach((list) => {
      const el = (
        <ListWrapper key={list.string}>
          <list.tag>
            {list.items.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </list.tag>
        </ListWrapper>
      );

      pushElement(arrayOfLists, el, 'list');
    });

    content.push(textRem);

    switch (hasList) {
      case true:
        return ({ isOpen }) => (
          <div
            ref={ref}
            className={`post_text font-avenir-regular ${isOpen ? 'open' : ''}`}
          >
            {content.map((item, i) => (
              <Fragment key={i}>{item}</Fragment>
            ))}
          </div>
        );
      default:
        return ({ isOpen }) => (
          <Text
            ref={ref}
            className={`post_text font-avenir-regular ${isOpen ? 'open' : ''}`}
          >
            {content.map((item, i) => (
              <Fragment key={i}>{item}</Fragment>
            ))}
          </Text>
        );
    }

    return ({ isOpen }) => (
      <Text
        ref={ref}
        className={`post_text font-avenir-regular ${isOpen ? 'open' : ''}`}
      >
        {content.map((item, i) => (
          <Fragment key={i}>{item}</Fragment>
        ))}
      </Text>
    );
  })();

  return (
    <PostStyle
      ref={wrapper}
      data-title={encodeTitle(title)}
      $isActive={isActive}
    >
      <Text className={'post_title font-argent'}>{title}</Text>
      <div className={'post_content'}>
        <Image
          className={'post_logo'}
          src={userpic ?? '/images/svg/post-logo.svg'}
        />
        <div
          ref={content}
          style={{ '--max-height': '1100px' }}
          className={`post_content__text ${isOpen ? 'open' : ''}`}
        >
          <Text className={'post_username font-avenir-regular'}>
            {username}
          </Text>
          <MainContent isOpen={isOpen} />
        </div>
      </div>
      <div className={'post_footer'}>
        {canExpand ? (
          <div className={'post_expand_btn'} onClick={expand} data-expand>
            <Text
              text={isOpen ? 'collapse' : 'expand'}
              className={'post_expand_text font-avenir-regular'}
            />
            <Icon
              name={'arrow-down icon'}
              className={`post_icon_expand ${isOpen ? 'open' : ''}`}
            />
          </div>
        ) : (
          <div />
        )}
        <div className={'post_buttons'}>
          <Icon name={'copy'} onClick={copyText} />
          <Icon
            name={'share'}
            className={`share_button ${isSharePopup ? 'active' : ''}`}
            onClick={share}
          />
          <Icon
            name={`${isInFavourite ? 'bookmark_fill' : 'bookmark'}`}
            onClick={addToFavouriteHandler}
          />
        </div>
      </div>
    </PostStyle>
  );
};

export default withUIContext(withLanguageContext(Post, ['translate']), [
  'openPopup',
  'showNotification',
  'popupIsOpen',
]);
